import styled from 'styled-components'
import { Typography } from '@olaisaac/design-system'

import { spacing, fontWeight, fontSize, colors, borderRadius } from '@gravity/tokens'
import { Card } from '@gravity/card'

export const StyledCard = styled(Card)`
  background-color: ${colors['colors-background-neutral-2']};
  border-radius: ${borderRadius[8]};
  border-style: none;
`
export const GrayTitleCard = styled(Typography)`
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
  margin-bottom: ${spacing[6]};
`

export const SubtitleGrayCard = styled(Typography)`
  font-size: ${fontSize.lg};
  font-weight: ${fontWeight.medium};
`
export const Subtitle = styled(Typography)`
  font-size: ${spacing[4]};
  line-height: ${spacing[6]};

  color: ${colors['colors-text-main-3']};
`
